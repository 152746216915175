import ReactLoading from 'react-loading';

import { LoadingContainer } from './styles';

const LoadingIndicator = () => (
  <LoadingContainer>
    <ReactLoading
      type="spinningBubbles"
      height={200}
      width={100}
      color="#00aeef" // Primary color
    />
  </LoadingContainer>
);
export default LoadingIndicator;
