import { useEffect, useState } from 'react';
import { growl } from '@crystallize/react-growl';

import { useQuery } from '../../hooks/query';
import api from '../../services/api';
import logo from '../../assets/images/due-logo.png';
import packageImg from '../../assets/images/package.png';
import {
  Container,
  Header,
  Logo,
  Main,
  Content,
  History,
  HistoryItem,
  OriginDestinyContainer,
  Origin,
  Destiny,
  Package,
} from './styles';
import LoadingIndicator from '../../components/LoadingIndicator';

const Tracking = () => {
  const query = useQuery();

  const [trackingInfo, setTrackingInfo] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchTrackingInfo() {
      try {
        const response = await api.get(`/tracking/${query.get('nf')}`);
        setTrackingInfo(response.data);
      } catch (err) {
        const message = err.response
          ? err.response.data.message
          : 'Ocorreu um erro';
        await growl({
          title: 'Erro',
          message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    fetchTrackingInfo();
  }, []);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Container>
        <Logo src={logo} alt="Logomarca da Due Laser" />
        <h1>Rastreamento de envio</h1>
        <Header>
          <p>{`NF ${trackingInfo.invoiceNumber || ''}`}</p>
          <p>{`Previsão de entrega: ${
            trackingInfo.deliveryPrevision || ''
          }`}</p>
        </Header>
        <Main>
          <Content>
            <OriginDestinyContainer>
              <Origin>
                <strong>ORIGEM</strong>
                <p>{trackingInfo.sender}</p>
                <p>{trackingInfo.loadOrigin}</p>
              </Origin>
              <Destiny>
                <strong>DESTINO</strong>
                <p>{trackingInfo.addressee}</p>
                <p>{trackingInfo.loadDestiny}</p>
              </Destiny>
            </OriginDestinyContainer>
            <Package src={packageImg} alt="Pacote" />
          </Content>
          <History>
            {trackingInfo.history &&
              trackingInfo.history.map((h) => (
                <HistoryItem key={h.status}>
                  <hr />
                  <p>{h.status}</p>
                  <p>{h.date}</p>
                </HistoryItem>
              ))}
          </History>
        </Main>
      </Container>
    </>
  );
};

export default Tracking;
