import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  padding: 10px;

  @media (max-width: 620px) {
    margin-top: 20px;
  }
`;

const Header = styled.header`
  background-color: #dae0eb;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px 15px 30px;
  width: 100%;
  max-width: 700px;
  margin-top: 30px;
`;

const Logo = styled.img`
  margin-bottom: 20px;
`;

const Main = styled.main`
  width: 100%;
  max-width: 700px;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const History = styled.div`
  display: flex;
  margin-top: 40px;

  @media (max-width: 620px) {
    flex-direction: column;
    align-items: center;
  }
`;
const HistoryItem = styled.div`
  margin: 10px 20px 10px 20px;
  max-width: 120px;

  hr {
    border: 0;
    background-color: #21b15f;
    border-radius: 5px;
    height: 5px;
    width: 115px;
    margin-bottom: 4px;
  }

  p {
    margin-bottom: 5px;
  }
`;

const OriginDestinyContainer = styled.div``;

const Origin = styled.div``;

const Destiny = styled.div`
  margin-top: 20px;
`;

const Package = styled.img`
  max-width: 120px;
  max-height: 120px;
  margin-left: 10px;

  @media (max-width: 420px) {
    display: none;
  }
`;

export {
  Container,
  Header,
  Logo,
  Main,
  Content,
  History,
  HistoryItem,
  OriginDestinyContainer,
  Origin,
  Destiny,
  Package,
};
